import React from "react"
import Sticky from "react-sticky-el"
import SEO from "../components/SEO/index"
import { Section, SectionTitle } from "../components/CompoundComponents/Section"
import {
  Card,
  CardContent,
  CardContentTitle,
} from "../components/CompoundComponents/Card"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Box } from "../components/CompoundComponents/Box"
import { ShoppingConsumer } from "../layouts/index"
import NavBar from "../components/navbar"
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {PortableText} from "@portabletext/react";

const SealantPage = ({data}) => {
  return (
    <>
      <SEO
        title="UZMBC - Multi Business Company - Герметики на основе ПВХ"
        description="Multi Business Company - Герметики на основе ПВХ"
      />
      <Sticky className="sticky-selector" topOffset={150}>
        <div>
          <NavBar />
        </div>
      </Sticky>
      <Section>
        <SectionTitle bottom>Герметики на основе ПВХ</SectionTitle>
        <Columns>
            {
                data.product.edges.map((item, i) => (
                    <Column is4>
                        <ShoppingConsumer>
                            {({ isModal, handleClick }) => (
                                <>
                                    <div onClick={() => handleClick(item.node.title)}>
                                        <Card>
                                            <CardContent>
                                                <CardContentTitle>
                                                    {item.node.title}
                                                </CardContentTitle>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div
                                        className={`modal ${isModal === item.node.title && "is-active"}`}
                                    >
                                        <div className="modal-background"></div>
                                        <div className="modal-content">
                                            <Box>
                                                {
                                                   item.node.image && <GatsbyImage
                                                        image={getImage(item.node.image.asset.gatsbyImageData)}
                                                    />
                                                }
                                                <div className="content">
                                                    <PortableText
                                                        value={item.node.block}
                                                    />
                                                </div>
                                            </Box>
                                        </div>
                                        <button
                                            onClick={handleClick}
                                            className="modal-close is-large"
                                            aria-label="close"
                                        ></button>
                                    </div>
                                </>
                            )}
                        </ShoppingConsumer>
                    </Column>
                ))
            }
        </Columns>
      </Section>
    </>
  )
}

export const SealantPageQuery = graphql`
  query {
      product: allSanitySealant {
        edges {
          node {
            title
            image {
              asset {
                gatsbyImageData
              }
            }
            block : _rawBlock(resolveReferences: {maxDepth: 10})
          }
        }
      }
  }
`

export default SealantPage
